import { HStack } from "@@panda/jsx";
import { PaperClipIcon } from "@heroicons/react/24/solid";
import { ResultOf } from "@internal/gql/client";
import { DealDocuments } from "@internal/gql/generated/types";
import { createColumnHelper } from "@tanstack/react-table";
import { Typography } from "components/display/Typography/Typography";
import { FilesModal } from "components/interaction/FilesModal/FilesModal";
import { Button } from "components/main/Button";
import { InformationTooltip } from "components/main/InformationTooltip";
import { formatInstrumentTypename } from "screens/deals/utils";
import { formatIsoDate } from "utility/dates";

import { CompanyCell } from "../CompanyCell/CompanyCell";
import { LeadCell } from "../LeadCell/LeadCell";
import { portfolioDealsQuery } from "./usePortfolioDeals";
import { formatDealDocuments } from "./utils";

export enum Column {
  Company = "company",
  FinalInv = "final-investment",
  Documents = "documents",
  Lead = "lead",
  ClosedDate = "close-date",
}

type PortfolioDeal = ResultOf<
  typeof portfolioDealsQuery
>["portfolioDeals"][number];

const columnHelper = createColumnHelper<PortfolioDeal>();

export const columns = [
  columnHelper.accessor("tradeName", {
    id: Column.Company,
    header: "Company",
    enableSorting: true,
    cell: (props) => (
      <CompanyCell
        name={props.row.original.tradeName}
        logo={props.row.original.logoUrl}
        type={formatInstrumentTypename(
          props.row.original.instrument.__typename
        )}
      />
    ),
  }),
  columnHelper.accessor("investment.finalInvestmentAmount.amount", {
    id: Column.FinalInv,
    header: () => (
      <HStack
        justifyContent={{ base: "flex-end", lg: "flex-start" }}
        alignItems="center"
        whiteSpace="noWrap"
      >
        Final investment{" "}
        <InformationTooltip
          content="Your investment amount net of all fees"
          alt="Final investment information"
        />
      </HStack>
    ),
    enableSorting: true,
    cell: (props) => {
      const amount =
        props.row.original.investment?.finalInvestmentAmount?.formatted ?? "";
      return (
        <Typography
          variant="body"
          color={amount ? "white" : "grey.gunsmoke"}
          textAlign={{ base: "right", lg: "left" }}
        >
          {amount || "–"}
        </Typography>
      );
    },
  }),
  columnHelper.display({
    id: Column.Documents,
    header: "Documents",
    enableSorting: false,
    cell: (props) => {
      // SafeCast: documents are always present as per our BE validation, the schema marks them as possibly `null` for some reason...
      const documents = props.row.original.documents || ({} as DealDocuments);
      const withDocs = Object.keys(documents).length;

      return withDocs ? (
        <FilesModal
          triggerSlot={
            <Button
              variant="secondary"
              mt="s"
              aria-label="Open deal files"
              borderRadius="full"
              px="0!"
              w="2rem!"
              h="2rem!"
              position="relative"
              zIndex="banner"
            >
              <PaperClipIcon color="white" width="0.8rem" />
            </Button>
          }
          files={documents ? formatDealDocuments(documents || []) : []}
        />
      ) : (
        "–"
      );
    },
  }),
  columnHelper.accessor("lead.displayName", {
    id: Column.Lead,
    header: "Lead",
    enableSorting: true,
    cell: (props) => (
      <LeadCell name={props.row.original.lead?.displayName ?? ""} />
    ),
  }),
  columnHelper.accessor("closedDate", {
    id: Column.ClosedDate,
    header: "Close date",
    enableSorting: true,
    cell: (props) => {
      const date = props.row.original.closedDate;
      return (
        <Typography variant="body" color="grey.gunsmoke">
          {date ? formatIsoDate(date) : "–"}
        </Typography>
      );
    },
  }),
];
