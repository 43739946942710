import { InvitedDeal } from "./columns";

export function sortInvitedDeals(deals: InvitedDeal[] | undefined) {
  if (!deals || !deals.length) return [];

  const commRequired = deals.filter(
    (deal) => deal.investment?.status === "COMMITMENT_REQUIRED"
  );

  const paymentRequired = deals.filter(
    (deal) => deal.investment?.status === "PAYMENT_REQUIRED"
  );

  const paymentReceived = deals.filter(
    (deal) => deal.investment?.status === "PAYMENT_RECEIVED"
  );

  const transferInitiated = deals.filter(
    (deal) => deal.investment?.status === "TRANSFER_INITIATED"
  );

  return [
    ...commRequired,
    ...paymentRequired,
    ...paymentReceived,
    ...transferInitiated,
  ];
}
